<template>

    <b-row   >
        
        
               
                <b-col  sm="12" md="12" lg="12" xl="12" class="div_logo">
        
                    <b-link
                      :to="{ path: '/'}"
                    > <img style="width: 150px;" src="/img/eqcoop.svg"/> </b-link>
        
        
                </b-col>
                   
          
    
        
                <b-col sm="12" md="2" lg="2" xl="2" >
        
                </b-col>
        
                <b-col sm="12" md="8" lg="8" xl="8" align="center">
    
                    <img style="width: 150px;margin-top: 15px;" src="/img/error.svg"/>
    
                    <h1 class="h1_pay">Pago cancelado</h1>
    
                    <p class="p_pay">Recuerde que puede realizar este pago desde el listado de recargas con PayPal en el menú de recargas en EQCoop</p>
    
                    <b-row>
    
                        <b-col sm="12" md="4" lg="4" xl="4" >
        
                            </b-col>
    
    
                            <b-col sm="12" md="4" lg="4" xl="4" >
    
                                <b-button
                                variant="primary"
                                class="mb-2 btn-sm-block"
                                :to="{name: 'dashboard-analytics'}"
                                size="lg"
                                block
                                >
                                Ir al dashboard
                                </b-button>
                                
                            </b-col>
    
                            <b-col sm="12" md="4" lg="4" xl="4" >
                                
                            </b-col>
    
                    </b-row>
    
            
        
            
        
                    </b-col>
                    
                    <b-col sm="12" md="2" lg="2" xl="2">
        
        </b-col>
          
    
                <b-col
                    style="color:white !important;    position: absolute;bottom: 0;width: 100%;"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    class="text-center align-items-center justify-content-xl-between fixed_footer"
                  >
           
                
        
                    <a
                      target="_blank"
                      title="Facebook de EQCoop"
                      href="https://www.facebook.com/eqpay.oficial"
                    >   <feather-icon
                     icon="FacebookIcon"
                     size="25"
                     class="redesWhite"
                     /></a>
                    <a
                      target="_blank"
                      title="Instagram de EQCoop"
                      href="https://www.instagram.com/eqpay.oficial"
                    > <feather-icon
                     icon="InstagramIcon"
                     size="25"
                     class="redesWhite"
                     /></a>
                    <a
                      target="_blank"
                      title="Email de EQCoop"
                      href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
                    > <feather-icon
                     icon="MailIcon"
                     size="25"
                     class="redesWhite"
                     /></a>
                    <a
                      target="_blank"
                      title="Tiktok de EQCoop"
                      href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
                    > <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      widht="10"
                      height="22"
                      viewBox="0 0 448 512"
                    ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>
        
                    <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
                        class="color_a"
                      href="#"
                      target="_blank"
                    >Términos y condiciones</a> - <b-link
                    class="color_a"
                      :to="{ name: 'privacidad'}"
                      target="_blank"
                    >Política de privacidad y condiciones de servicios</b-link> -  <a
                    class="color_a"
                      href="#"
                      target="_blank"
                    >Reclamos</a></p>
        
                    <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> Copyright &copy; {{ year }} <a
                      href="https://web.eqcoops.com"
                      target="_blank"
                      class="color_a"
                    >- EQCoop</a></p>
        
                  </b-col>
     
        
        </b-row>
          </template>
          
          <script>
          /* eslint-disable global-require */
          import { BRow, BCol, BButton, BLink } from 'bootstrap-vue'
        
          
          export default {
            components: {
                BRow, BCol, BButton, BLink
            },
            data() {
        
             
              return {
                year: new Date().getFullYear(),
              }
            },
            computed: {
        
            }, beforeCreate() {

                if (this.$route.query.token) {

                    window.history.pushState({}, document.title, window.location.pathname); //elimina los query params

                    }else{
                        window.location.replace("https://eqcoops.com/dashboard");
                    }
                },mounted(){


          
        
        
             
                document.title = 'EQCoop - Pago cancelado';
                
            },
            methods: {
        
        
            }
          }
          </script>
          
          <style lang="scss">
        
       
        
          </style>
          